export const isDev = import.meta.env.DEV;
export const isStage = window.location.host.search('hunter-dev') !== -1;
export const isProd = window.location.hostname.includes('pay.prostopay.net');

/**
 *
"1","Снековий автомат"
"2","Кавовий автомат"
"3","Спарка (мастер - снек)"
"4","Спарка (мастер - кава)"
"5","Кавомашина"
"6","Фрешевий"
"7","Морозиво"
"8","Цигарки"
"9","Кондомат"
"10","Холодильник"
"11","Розлив води - пульс"
"12","Снековий - пульс"
"13","Холодильник Смарт"
 */
// export const TAB_ID_STORAGE_KEY = 'tbbd';
export const TAB_START_TIMESTAMP_STORAGE_KEY = 'start';
export const TAB_OPEN_DELAY = 3000;
export const TAB_CLOSE_DELAY = 500;
export const TAB_SYNC_DURATION = 5000;

export const STORAGE_RENEW_INTERVAL = 24 * 3600000; // 1 day

export const STORAGE_KEYS = ((canReadKeys = isDev || isStage) => {
    return {
        VM_STATE: canReadKeys ? 'vm_state' : 'vms',
        VM_SYNC_TIMESTAMP: canReadKeys ? 'vm_sync_timestamp' : 'vmst',
        VM_SYNC_TIME: canReadKeys ? 'vm_sync_time' : 'vmsT',
        TAB_ID: canReadKeys ? 'current_tab_id' : 'tbbd',
        TAB_IDS: canReadKeys ? 'existing_tabs' : 'pptbs', // TODO check if it is used
        TAB_START_TIMESTAMP: canReadKeys ? 'start' : '5T@t',
        TAB_START_TIME: canReadKeys ? 'tab start time' : 'stT1m3', // TODO rename to more cryptic name vm_state
        TAB_EXPIRED: canReadKeys ? 'session-expired' : 'se553xp',
        ORDER_STATE: canReadKeys ? 'order_state' : 'orsTt',
        ORDER_SYNC_TIMESTAMP: canReadKeys ? 'order_sync_timestamp' : 'or_5t_tm',
        DEBUG_IS_ALLOWED: 'qrDebug',
        APP_VERS: canReadKeys ? 'app_version' : 'VwAp83r51Ya',
        STORE_TIMESTAMP: canReadKeys ? 'store_timestamp' : 'sttR-T1m',
        STORE_TIME: canReadKeys ? 'store_time' : 'stRT1m34',
    };
})();

export const MACHINE_TYPES = {
    SNACK_MACHINE: '1',
    COFFEE_AUTOMAT: '2',
    SNACK_MACHINE_SPARKA: '3',
    COFFEE_MACHINE_SPARKA: '4',
    COFFEE_MACHINE: '5',
    FRESH_MACHINE: '6',
    ICE_CREAM_MACHINE: '7',
    CIGARETTE_MACHINE: '8',
    CONDOM_MACHINE: '9',
    FRIDGE: '10',
    WATER_MACHINE_PULS: '11',
    SNACK_MACHINE_PULS: '12',
    FRIDGE_SMART: '13',
};

export const CT_TYPES = {
    NULL: null, // default for CT1 and CT10
    CT1: 1,
    CT5: 5,
    CT10: 10,
};
/*
From Volodymyr Serednii:
  1 - звернення.
  2 не знаю
  3 - вибір продукту, здається,
  4 - підтвердження/скасування продажу (залежить від поля status яке приходить з цим кешлесом),
  5 - ?
  6 - завершення,

  another description (from https://prostopay.atlassian.net/wiki/spaces/SW/pages/155451432/Current+WEB+Payments+details):
  1 - підключення до апарата
  2 - неуспішне списання (коштів)
  3 - успішне списання (кошти списані з клієнта)
  4 - невдале приготування (кошти успішно повернуто)
  5 - невдале приготування, неуспішне повернення коштів
  6 - Відшкодовано вручну
  */

export const ORDER_STATUS = {
    ZERO: 0,
    PREPAID: 1, // payment successful - used for CT5
    PAYMENT_FAIL: 2, // payment failed
    COMPLETED: 3, // completed
    REFUNDED: 4, // refunded
    FIVE: 5,
};

// after order check we have order.device_status
export const DEVICE_STATUS = {
    HAS_CONNECTION: 1,
    PRODUCT_SELECTED: 3,
    PAID: 4,
    CONNECTED: 5,
    COMPLETED: 6,
    NULL: null,
};

export const NFC_IS_AVAILABLE = '1'; // value in URL that indicates that NFC is available

// from https://prostopay.atlassian.net/wiki/spaces/SW/pages/155451432/Current+WEB+Payments+details
export const PAYMENT_PROVIDER = {
    MASTERPASS: 0,
    GOOGLE_PAY: 1,
    APPLE_PAY: 2,
    KASPI: 3,
    TBC: 4,
    TWO: 2, // in code was 2 - so it is unclear how to name
    MYPOS: 5,
};

export const AVAILABLE_WATER_VOLUMES = [5, 6, 10, 12, 19];

export const AVAILABLE_CT5_VM_TYPES = [
    MACHINE_TYPES.SNACK_MACHINE,
    MACHINE_TYPES.COFFEE_AUTOMAT,
    MACHINE_TYPES.SNACK_MACHINE_SPARKA,
    MACHINE_TYPES.COFFEE_MACHINE_SPARKA,
    MACHINE_TYPES.COFFEE_MACHINE,
    MACHINE_TYPES.FRESH_MACHINE,
    MACHINE_TYPES.ICE_CREAM_MACHINE,
    MACHINE_TYPES.CIGARETTE_MACHINE,
    MACHINE_TYPES.CONDOM_MACHINE,
];

/**
 * Machine paths for Connecting screen when path is depended on machine type
 */
export const MACHINE_PATHS = {
    [MACHINE_TYPES.FRIDGE]: 'payment10',
    [MACHINE_TYPES.SNACK_MACHINE_PULS]: 'snack',
    [MACHINE_TYPES.WATER_MACHINE_PULS]: 'water',
};
// permission for service/payment pages
export const QR_SERVICES = {
    NOTHING_IS_ALLOWED: 0,
    ONLY_PAYMENT: 1,
    SEPARATED_SERVICES: 2,
    ALL_IS_PERMITTED: 3,
};

export const PAYMENT_ERROR = {
    TRANSACTION_DECLINED: '1',
    ACQUIRER_PROBLEM: '2',
    BANK_PROBLEM: '3',
    TECHNICAL_PROBLEM: '4',
    LIMIT_PROBLEM: '5',
    LOW_BALANCE: '6',
    INVALID_CVV: '7',
};

export const ERROR_MESSAGES: { [key: string]: string } = {
    [PAYMENT_ERROR.TRANSACTION_DECLINED]: 'Transaction declined',
    [PAYMENT_ERROR.ACQUIRER_PROBLEM]: 'Acquiring bank problem',
    [PAYMENT_ERROR.BANK_PROBLEM]: 'Bank issue',
    [PAYMENT_ERROR.TECHNICAL_PROBLEM]: 'Technical problems',
    [PAYMENT_ERROR.LIMIT_PROBLEM]: 'Limit problem',
    [PAYMENT_ERROR.LOW_BALANCE]: 'Low balance',
    [PAYMENT_ERROR.INVALID_CVV]: 'Invalid CVV',
};

export const FEEDBACKS_ESTIMATE = {
    POSITIVE_SMILE: 1,
    NEGATIVE_SMILE: 0,
};

export const FEEDBACKS_CUSTOMER_STATUS = {
    AFTER_SMILE_PAGE: 2,
    AFTER_WISHES_PAGE: 3,
    AFTER_CONTACT_PAGE: 4,
};

export const DISABLE_OR_ENABLE_BUTTON = [
    { background: 'linear-gradient(270deg, #5c81ed 5.76%, #b984fa 92.6%)' },
    { background: '#808080b8' },
];

export const FEEDBACK_SRC = {
    SERVICE: 0,
    PAYMENT: 1,
};

export const API = {
    CHECK_QR: isDev ? '/dev/qr.json' : '/api/check',
    ORDER: '/api/order',
    ORDER_AMOUNT: isDev ? '/dev/order_paid.json' : '/api/order-amount',
    ORDER_GOOGLE_PAYMENT: isDev ? '/dev/order_paid.json' : '/api/paymentGoogle',
    ORDER_MYPOS_PAYMENT: isDev ? '/dev/order_paid.json' : '/api/paymentMyPos',
    VALIDATE_APPLE_MERCHANT: '/api/appleValidateMerchant',
    LEGAL_UPDATE: '/api/get_legal_update',
    FEEDBACK_DATA: '/api/get_feedback_data',
    CONNECT: '/api/connect',
    CANCEL: '/api/cancel',
    RECEIPT: '/api/receipt',
};
export const NEW_PRIVACY_POLICY_URL = 'https://docs.prostopay.net'
