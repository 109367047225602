import React from 'react';
import { isDev } from '../../../constants';
import { trackClick } from '../../../utils/tracking';
import * as MyPOSEmbedded from 'mypos-embedded-checkout';
import { fetchData } from '../../../utils/general';

interface MyPosPayButtonProps {
    orderId: string,
    orderAmount: number;
    merchantName: string,
    currencyCode: string;
    onPaymentCompleted: (responce: any) => void;
    onError: (err?: any) => void;
    innerClassName?: string;
}

const MyPosPayButton = (props: MyPosPayButtonProps) => {
    const {
        orderId,
        orderAmount,
        currencyCode,
        merchantName,
        innerClassName = '',
        onPaymentCompleted,
        onError,
    } = props;

    const startPayment = async () => {
        const response = await fetch(`/api/paymentMyPosStart?id=${orderId}`);
        if (!response.ok) {
            throw Error(`Response: ${response.statusText}`);
        }
        const result = await response.json();
        const sessionToken = result.sessiontoken;

        if (MyPOSEmbedded.IS_APPLE_PAY_AVAILABLE) {
            MyPOSEmbedded.createApplePayButton(
                'embeddedButtons', //div tag ID
                sessionToken, //Received session token from IPCPaymentSessionCreate
                {
                    isSandbox: isDev, //Is sandbox env
                    merchantName: merchantName, //Custom Merchant name
                    onSuccess: function (data: any) {
                        //On payment success
                        onPaymentCompleted(data)
                    },

                    onDecline: function (err: any) {
                        //On payment Declined
                        onError(err);
                    }
                }
            ).then((_: any) => {
                //When button is loaded
            }).catch((err: any) => {
                //Catched error loading
                console.error(err);
                onError(err);
            })
        } else {
            MyPOSEmbedded.createGooglePayButton(
                'embeddedButtons', //div tag ID
                sessionToken, //Received session token from IPCPaymentSessionCreate
                {
                    isSandbox: isDev, //Is sandbox env
                    onSuccess: function (data: any) {
                        //On payment Success
                        console.log("Completed", data);
                        onPaymentCompleted(data);
                    },

                    onDecline: function (err: any) {
                        //On payment Declined
                        console.error(err);
                        onError(err);
                    }
                }
            ).then((sessionData: any) => {
                //When button is loaded
            }).catch((err: any) => {
                //Catched error loading
                console.error(err);
                onError(err);
            });
        }
    }


    return (
        <div id="embeddedButtons" >
            <img src='http://mypos.com/img/vmypos/lg/btn-en.png'
                onClick={() => {
                    trackClick('MyPos pay button', {
                        amount: orderAmount,
                        currency: currencyCode,
                    });
                    startPayment();
                }} />
        </div>
    );
};

export default React.memo(MyPosPayButton);
